html, body {
  width: 100%;
  margin: 0px;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}